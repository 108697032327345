import React, { useState } from 'react'
import { Box, CssBaseline } from '@mui/material'
import TopBar from './TopBar'
import SideNav from './SideNav'
import BottomTabs from '../BottomTabs/BottomTabs'
import '../../styles/tracking-styles.css'
import useIseMobile from '../../customHooks/useIsMobile'
interface LayoutProps {
  children: React.ReactNode
  isLoggedIn: boolean
}

const Layout: React.FC<LayoutProps> = ({ children, isLoggedIn }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const isMobile = useIseMobile()
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: 0,
      }}
    >
      <CssBaseline />
      <TopBar
        isLoggedIn={isLoggedIn}
        onMenuToggle={handleDrawerToggle}
        open={drawerOpen}
      />
      <Box
        sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden', padding: 0 }}
      >
        {isLoggedIn && !isMobile && (
          <SideNav open={drawerOpen} onDrawerToggle={handleDrawerToggle} />
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: '100%',
            height: '100%',
            overflow: 'auto',
            marginTop: 8, // Adjust based on your TopBar height
            marginBottom: isMobile ? 7 : 0, // Add bottom margin for mobile to accommodate BottomTabs
            padding: 0,
          }}
        >
          {children}
        </Box>
      </Box>
      {isLoggedIn && isMobile && <BottomTabs />}
    </Box>
  )
}

export default Layout
