import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAllGeofences } from './geofenceSlice'
import { useAppDispatch } from '../../app/hooks'
import type { RootState } from '../../app/store'
import { fetchGeofences } from './geofenceSlice'
import {
  Box,
  Typography,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

function GeofencesList() {
  const geofences = useSelector(selectAllGeofences)
  const dispatch = useAppDispatch()
  const geofencesStatus = useSelector(
    (state: RootState) => state.geofences.status,
  )
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  const [expanded, setExpanded] = useState<string | false>(false)

  useEffect(() => {
    if (geofencesStatus === 'idle') {
      dispatch(fetchGeofences({ token: sessionToken, organizationId }))
    }
  }, [geofencesStatus, dispatch, sessionToken, organizationId])

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  if (geofencesStatus === 'loading') {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Geofences
      </Typography>
      <List>
        {geofences.map((geofence) => (
          <ListItem key={geofence.id} disablePadding>
            <Accordion
              expanded={expanded === geofence.id}
              onChange={handleChange(geofence.id)}
              sx={{ width: '100%', mb: 1 }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{geofence.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  Type: {geofence.geometry.type}
                </Typography>
                <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                  Coordinates: {JSON.stringify(geofence.geometry.coordinates)}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default GeofencesList
