import {
  Avatar,
  Box,
  Icon,
  IconButton,
  Menu,
  Stack,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { getGeoAccess } from '../../api/geofences'
import {
  GeofenceAccess,
  Vehicle,
  Geofence,
  secureCompany,
} from '../../api/types'
import { RootState } from '../../app/store'
import {
  fetchVehicles,
  selectAllVehicles,
} from '../../redux_features/vehicles/vehicleSlice'
import useWindowSize from '../../customHooks/useWindowSize'

import { getLocalDate } from '../VehicleHistoryController'
import { useLocation, useSearchParams } from 'react-router-dom'
import { fetchGeofences } from '../../redux_features/geofences/geofenceSlice'
import { useAppDispatch } from '../../app/hooks'

export type GeoAccessListProps = {
  map?: google.maps.Map | undefined
}

const GeoAccessList = (props: GeoAccessListProps) => {
  const dispatch = useAppDispatch()
  const windowSize = useWindowSize()
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  const vehicleStatus = useSelector((state: RootState) => state.vehicles.status)
  const Geofences = useSelector((state: RootState) => state.geofences.geofences)

  const geofencesstatus = useSelector(
    (state: RootState) => state.geofences.status,
  )

  let vehicles: Vehicle[] | undefined
  vehicles = useSelector(selectAllVehicles)
  const [events, setevents] = useState<GeofenceAccess[]>()
  let [searchParams, setSearchParams] = useSearchParams()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const startD = searchParams.get('startDate')
  const endD = searchParams.get('endDate')
  const geofenceId = searchParams.get('geofenceId')
  let location = useLocation()
  // TODO review duplicate!!!
  let startDate: Date
  let endDate: Date

  if (startD && endD) {
    startDate = new Date(0)
    endDate = new Date(0)

    startDate.setUTCSeconds(Number(startD))
    endDate.setUTCSeconds(Number(endD))
  } else {
    startDate = new Date()
    endDate = new Date()

    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
  }

  useEffect(() => {
    const secCompany: secureCompany = {
      token: sessionToken,
      organizationId: organizationId,
    }
    if (geofencesstatus === 'idle') dispatch(fetchGeofences(secCompany))
    if (vehicleStatus === 'idle') dispatch(fetchVehicles(secCompany))
  }, [geofencesstatus, dispatch])

  useEffect(() => {
    const geof = Geofences?.find((value: Geofence) => value.id === geofenceId)

    if (geof?.geometry.type === 'Polygon') {
      const bounds = {
        north: geof.geometry?.coordinates[0][0][1], // north lat
        south: geof.geometry?.coordinates[0][2][1], // south lat
        east: geof.geometry?.coordinates[0][2][0], //east lat
        west: geof.geometry?.coordinates[0][0][0], // west lat
      }

      props.map?.fitBounds(bounds)
    }
  }, [geofencesstatus])

  const getGeofencesData = async () => {
    await getGeoAccess(organizationId, startDate, endDate, sessionToken)
      .then((data: GeofenceAccess[]) => {
        console.log(data)
        setevents(data)
        console.log('done')
      })
      .catch((err: Error) => {
        if (navigator.onLine) {
          //do nothing if is offline because this was already checked
          console.log(' Please try again later' + err)
        }
      })
  }

  useEffect(() => {
    getGeofencesData()
  }, [])

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <div
      style={{
        top: 10,
        right: -140,
        margin: 10,

        width: '20%',
        zIndex: 1,
        height:
          windowSize.width > 800
            ? windowSize.height * 0.8
            : windowSize.height / 2.3,
        overflow: 'scroll',
        scrollbarWidth: 'auto',
      }}
    >
      <Box>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              <NotificationsNoneIcon />
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      {open && anchorEl && (
        <Menu open={open} onClose={handleClose} onClick={handleClose}>
          {/* add Table\ header in geofence events */}
          <Table>
            {events?.map((acceso: GeofenceAccess, index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell size="small">
                      {acceso.accessType === 'exit' && (
                        <Tooltip title={'Exit'}>
                          <Icon
                            color="primary"
                            sx={{ marginRight: 2, color: '#f50057' }}
                          >
                            {'logout'}
                          </Icon>
                        </Tooltip>
                      )}
                      {acceso.accessType === 'enter' && (
                        <Stack direction={'row'}>
                          <Tooltip title={'Enter'}>
                            <Icon sx={{ marginRight: 2, color: '#4caf50' }}>
                              {'login'}
                            </Icon>
                          </Tooltip>
                        </Stack>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {
                          vehicles?.find(
                            (vehic: Vehicle) => vehic.id === acceso.vehicleId,
                          )?.name
                        }
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <label color="black">
                        {
                          Geofences?.find(
                            (value: Geofence) => value.id === acceso.geofenceId,
                          )?.name
                        }
                      </label>
                    </TableCell>
                    <TableCell>
                      <Typography style={{ fontSize: 12 }}>
                        {getLocalDate(acceso.timestamp)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
          </Table>
        </Menu>
      )}
    </div>
  )
}

export default GeoAccessList
