import React, { useRef, useState } from 'react'
import { AppBar, Toolbar, Typography, IconButton, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import UserProfileMenu from '../user/UserProfileMenu'
import UserNotifications from '../user/UserNotifications'
import logo from '../../assets/logo/Company_Logo.png'

import useIsMobile from '../../customHooks/useIsMobile'
import useClickAway from '../../customHooks/useClickAway'
import { useNavigate } from 'react-router-dom'

// const StyledAppBar = styled(AppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })<{ open?: boolean }>(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   background: '#FFFFFF',
//   transition: theme.transitions.create(['margin', 'width'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: `${drawerWidth}px`,
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }))

const drawerWidth = 160 // Make sure this matches your SideNav width
const compactDrawerWidth = 66 // Adjust this value based on your SideNav's compact width

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: 'rgba(255, 255, 255, 0.8)', // Slight transparency
  backdropFilter: 'blur(10px)', // Glassmorphism effect
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)', // Subtle shadow
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)', // Thin border
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: open
    ? `calc(100% - ${drawerWidth}px)`
    : `calc(100% - ${compactDrawerWidth}px)`,
  marginLeft: open ? `${drawerWidth}px` : `${compactDrawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

interface TopBarProps {
  isLoggedIn: boolean
  onMenuToggle: () => void
  open: boolean
  pageSpecificContent?: React.ReactNode
}

interface TopBarProps {
  isLoggedIn: boolean
  onMenuToggle: () => void
  pageSpecificContent?: React.ReactNode
}

const LogoContainer = styled('div')({
  marginRight: '32px',
  display: 'flex',
  alignItems: 'center',
})

const TopBar: React.FC<TopBarProps> = ({
  isLoggedIn,
  onMenuToggle,
  open,
  pageSpecificContent,
}) => {
  const [anchorLogin, setAnchorLogin] = useState(null)
  const [anchorBurguer, setAnchorBurguer] = useState(null)
  const dropdownRef = useRef(null)
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const handleClickLogin = (event: any) => {
    setAnchorLogin(event.currentTarget)
  }

  const handleClickBurguer = (event: any) => {
    setAnchorBurguer(event.currentTarget)
  }

  const handleCloseLogin = () => {
    setAnchorLogin(null)
  }
  const handleCloseBurguer = () => {
    setAnchorBurguer(null)
  }

  const handleCloseAll = () => {
    handleCloseBurguer()
    handleCloseLogin()
  }
  const handleShowMenu = () => {}
  useClickAway(dropdownRef, handleCloseAll)

  return (
    <StyledAppBar position="fixed" elevation={0} open={open}>
      <Toolbar>
        <LogoContainer>
          <a href="https://vermmis.com">
            <img src={logo} width="102" height="63" alt="Logo" />
          </a>
        </LogoContainer>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onMenuToggle}
        >
          {/* Add menu icon here */}
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          {/* App title or other content */}
        </Typography>
        {pageSpecificContent}

        {isLoggedIn ? (
          <>
            <UserNotifications status={true} />
            <UserProfileMenu status={true} />
          </>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('login', { replace: true })
            }}
          >
            Login
          </Button>
        )}
        {/* 
        {Boolean(anchorLogin) && (
          <div
            ref={dropdownRef}
            style={{
              top: '100%',
              width: '300px',
              position: 'absolute',
              backgroundColor: 'white',
              right: isMobile ? 'unset' : '0', // Center for mobile, right for desktop

              // fix this styling
            }}
          >
            <SessionLogin compactView={true} />
          </div>
        )} */}
      </Toolbar>
    </StyledAppBar>
  )
}

export default TopBar
