import {
  InviteRole,
  RdxStateSession,
  secureUserCompany,
  UsersInfo,
} from '../../api/types'
import { useAppDispatch } from '../../app/hooks'
import { RootState } from '../../app/store'
import '../../styles/Login.css'
import { fetchUserSettings } from '../usersSettings/usersettingsSlice'
import { Copyright } from '../../common/Common'
import './SessionLogin.css'

import { authenticate, AuthResult } from './cogAccount'
import { setCredentials } from './sessionSlice'

import { Grid, TextField, Typography } from '@mui/material'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import { createTheme } from '@mui/material/styles'
import {
  CognitoUser,
  CognitoUserSession,
  IAuthenticationCallback,
} from 'amazon-cognito-identity-js'

import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import PasswordValidatorComponent from '../../components/Passwordvalidator'
import LeftContainer from './LeftContainer'
import React, { useState } from 'react'
import { InputAdornment, IconButton } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

export type SessionLoginProps = { compactView: boolean }

const SessionLogin = (props: SessionLoginProps) => {
  const navigate = useNavigate()

  let [searchParams] = useSearchParams()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [organization, setOrganization] = useState('')
  const [role, setRole] = useState<InviteRole[]>([])
  const [changePass, setChangePass] = useState(false)

  const [rolePicker] = useState(false)
  const [enablesave, setEnablesave] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [duplicate, setDuplicate] = useState('')
  const [userAttributes] = useState()
  const [coguser, setCognitoUser] = useState<CognitoUser>()
  const [errMsg, setErrMsg] = useState('')
  const [tempUsersData] = useState<UsersInfo[]>([])
  const [showPassword, setShowPassword] = useState(false)

  const dispatch = useAppDispatch()
  const sessionStatus = useSelector((state: RootState) => state.session.status)
  //

  let params = '?'
  searchParams.forEach((value, key) => {
    params += key + '=' + value + '&'
  })

  type IncompleteUserInfo = {
    email: string
    sub: string
  }

  const handleEmailBlur = () => {
    // Remove trailing spaces
    const trimmedEmail = email.trim()
    setEmail(trimmedEmail)
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }
  //move to cogAccount after testing

  const login = (email: string, password: string) => {
    authenticate(email, password)
      .then((result: AuthResult) => {
        if (result.success) {
          const idToken = result.session?.getIdToken()
          const payload = idToken?.payload

          if (payload && payload['custom:registrationComplete'] === 'true') {
            const userInfo = JSON.parse(payload['custom:userInfo'])
            proceed(result.session!, userInfo, '/dashboard')
          } else {
            const incompleteUserInfo: IncompleteUserInfo = {
              email: payload?.email,
              sub: payload?.sub,
            }
            proceed(
              result.session!,
              incompleteUserInfo,
              '/complete-registration',
            )
          }
        } else {
          // Handle different error cases
          switch (result.error?.code) {
            case 'auth_failed':
              setErrMsg('Incorrect username or password')
              break
            case 'missing_organization':
              setErrMsg('Organization information is missing')
              break
            case 'new_passsword':
              setChangePass(true) //this should be ok
              console.log('result.user', result.user)
              setCognitoUser(result.user)
              break
            default:
              setErrMsg('An unexpected error occurred. Please try again later.')
          }
        }
      })
      .catch((error) => {
        console.error('Authentication error:', error)
        setErrMsg('An unexpected error occurred. Please try again later.')
      })
  }

  const proceed = (session: CognitoUserSession, userInfo: any, url: string) => {
    //todo buserInfo type
    const idToken = session.getIdToken()
    const accessToken = session.getAccessToken()

    const sessionUserdata: RdxStateSession = {
      username: idToken.payload['cognito:username'],
      accesstoken: accessToken.getJwtToken(),
      email: idToken.payload['email'],
      userinfo: userInfo,
      idtoken: idToken.payload,
      profile: 'profile', // You might want to update this based on your needs
      organizationId:
        'organizationId' in userInfo ? userInfo.organizationId : '',
      role: userInfo.roles,
    }

    console.log('Setting credentials')
    dispatch(setCredentials(sessionUserdata))

    const secUser: secureUserCompany = {
      token: sessionUserdata.accesstoken,
      organizationId: userInfo.organizationId,
      userId: userInfo.userId,
    }

    dispatch(fetchUserSettings(secUser))
    console.log('REDIRECTING TO ', url)
    navigate(url)
  }

  const passworChallengeCallback: IAuthenticationCallback = {
    onSuccess: (
      session: CognitoUserSession,
      userConfirmationNecessary?: boolean,
    ) => {
      alert('Password changed Sucessfully')
      setChangePass(false)
    },
    onFailure: (err: any) => {
      alert('there was an error' + err)
    },
  }

  const handleSave = () => {
    coguser?.completeNewPasswordChallenge(
      newPassword,
      userAttributes,
      passworChallengeCallback,
    )
  }

  const selectRole = (e: any) => {
    e.preventDefault()
    setRole(e.target.value)
  }

  const selectOrganization = (e: any) => {
    e.preventDefault()
    setOrganization(e.target.value)
  }

  /**----------------------------------------------------------*/
  /** COMPONENT RETURN HERE                                    */
  /**----------------------------------------------------------*/
  return (
    <>
      {!props.compactView && <LeftContainer />}

      {!changePass ? (
        <Container component="main" maxWidth="xs">
          {rolePicker ? (
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',

                backgroundColor: 'white', //added by Yeyi
              }}
            >
              <label>Organization</label>
              {tempUsersData.length > 1 && (
                <select onChange={selectOrganization}>
                  {tempUsersData.map((item: UsersInfo, index: number) => {
                    return (
                      <option key={index} value={item.organizationId}>
                        {item.organizationId}
                      </option>
                    )
                  })}
                  <option> </option>
                </select>
              )}

              {tempUsersData[0].roles.length > 1 && (
                <>
                  <label>Role</label>
                  <select onChange={selectRole}>
                    {tempUsersData[0].roles.map(
                      (item: InviteRole, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        )
                      },
                    )}
                    <option> </option>
                  </select>
                </>
              )}
              {/* #TODO review multirole and multiorganization */}
              {/* <button onClick={() => proceed(rawdata)}>GO</button> */}
            </Box>
          ) : sessionStatus === 'succeeded' ? (
            <div>?</div>
          ) : (
            // <Box
            //   sx={{
            //     marginTop: 8,
            //     display: 'flex',
            //     flexDirection: 'column',
            //     alignItems: 'center',
            //     padding: 4,
            //     backgroundColor: 'white',
            //     borderRadius: 2,
            //     boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            //     transition: 'box-shadow 0.3s ease-in-out',
            //     '&:hover': {
            //       boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.15)',
            //     },
            //   }}
            // >
            //   <>
            //     <label>{errMsg}</label>

            //     <div>
            //       <img
            //         sizes="20"
            //         src="Company_Logo2.png"
            //         alt="Vermmis Company"
            //         style={{
            //           width: '80px',
            //           height: '50px',
            //           marginBottom: '16px',
            //         }}
            //       ></img>
            //       <h2>Sign in to your account</h2>

            //       <div>
            //         <TextField
            //           placeholder="jane@example.com"
            //           value={email}
            //           onChange={(event: {
            //             target: { value: React.SetStateAction<string> }
            //           }) => setEmail(event.target.value)}
            //           onBlur={handleEmailBlur}
            //           fullWidth
            //           margin="normal"
            //         >
            //           Email
            //         </TextField>
            //       </div>

            //       <TextField
            //         onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            //           setPassword(e.target.value)
            //         }
            //         value={password}
            //         type={showPassword ? 'text' : 'password'}
            //         fullWidth
            //         margin="normal"
            //         InputProps={{
            //           endAdornment: (
            //             <InputAdornment position="end">
            //               <IconButton
            //                 aria-label="toggle password visibility"
            //                 onClick={handleClickShowPassword}
            //                 onMouseDown={handleMouseDownPassword}
            //                 edge="end"
            //               >
            //                 {showPassword ? <VisibilityOff /> : <Visibility />}
            //               </IconButton>
            //             </InputAdornment>
            //           ),
            //         }}
            //       />
            //       <Grid container justifyContent="center">
            //         <Grid item xs>
            //           <Button
            //             type="submit"
            //             variant="contained"
            //             color="primary"
            //             onClick={() => {
            //               login(email, password)
            //             }}
            //           >
            //             Login
            //           </Button>
            //         </Grid>
            //       </Grid>
            //       <Grid container justifyContent="flex-start">
            //         <Grid item xs>
            //           <Link href="/forgotpassword" variant="body2">
            //             Forgot password?
            //           </Link>

            //           <Grid item alignSelf={'flex-end'}>
            //             <Link href="/signup" variant="body2">
            //               Don' have an account? Sign Up
            //             </Link>
            //           </Grid>
            //         </Grid>
            //         {props.compactView && (
            //           <Grid item>
            //             <Copyright sx={{ mt: 8, mb: 4 }} />
            //           </Grid>
            //         )}
            //       </Grid>
            //     </div>
            //   </>
            // </Box>
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 4,
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                  boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.15)',
                },
                maxWidth: 400,
                width: '100%',
                mx: 'auto',
              }}
            >
              {errMsg && (
                <Typography color="error" sx={{ mb: 2 }}>
                  {errMsg}
                </Typography>
              )}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <img
                  src="Company_Logo2.png"
                  alt="Vermmis Company"
                  style={{
                    width: '80px',
                    height: '50px',
                    marginBottom: '16px',
                  }}
                />
                <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
                  Sign in to your account
                </Typography>

                <TextField
                  placeholder="jane@example.com"
                  value={email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(event.target.value)
                  }
                  onBlur={handleEmailBlur}
                  fullWidth
                  margin="normal"
                  label="Email"
                />

                <TextField
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                  }
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  margin="normal"
                  label="Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => login(email, password)}
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                >
                  Login
                </Button>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    mt: 2,
                  }}
                >
                  <Link href="/forgotpassword" variant="body2">
                    Forgot password?
                  </Link>
                  <Link href="/signup" variant="body2">
                    Don't have an account? Sign Up
                  </Link>
                </Box>

                {props.compactView && (
                  <Box sx={{ mt: 4 }}>
                    <Copyright />
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Container>
      ) : (
        <>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h5>Please set a new password </h5>

              <TextField
                margin="normal"
                required
                fullWidth
                label="new password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                }}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                label="confirm password"
                value={duplicate}
                onChange={(e) => {
                  setDuplicate(e.target.value)
                }}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                fullWidth
                variant="contained"
                disabled={!enablesave || newPassword !== duplicate}
                className="btn btn-primary btn-block"
                onClick={handleSave}
              >
                save
              </Button>
            </Box>
            <Box>
              {/* TODO need improvmeents to be reusable */}
              <PasswordValidatorComponent
                password={newPassword}
                enableFunction={() => {
                  setEnablesave(true)
                }}
              />
            </Box>
          </Container>
        </>
      )}

      {/*-----------------------------------------------------*/}
      {/*SESSION LOGIN RIGHT CONTAINER ENDS HERE              */}
      {/*-----------------------------------------------------*/}
    </>
  )
}

export default SessionLogin
